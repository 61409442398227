/* eslint semi: ["error", "never"]*/
/* eslint arrow-parens: ["error", "as-needed"]*/
/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */

import ApplePayButton  from '@app/components/containers/apple-pay-button'
import ApplePayManager from '@app/lib/modules/apple-pay-manager'
import GooglePay       from '@app/components/containers/google-pay'
import PropTypes       from 'prop-types'
import React           from 'react'
import isEqual         from 'lodash/isEqual'

const expressCheckoutButtons = {
  apple_pay:  ApplePayButton,
  google_pay: GooglePay
}

class ExpressCheckoutPayments extends React.Component {

  /*
   * Render component
   */

  render() {

    /*
     * We should have at least one available express checkout payment in order to load the panel
     */

    /*
     * Currently, we are supporting only ApplePay so it is okay to have this early return
     * if the API is not available /in order not to load the h4, etc/
     */

    if (isEqual(this.props.payments, ['apple_pay']) && !ApplePayManager.isApplePaySupported()) {

      return null
    }

    return (
      <div className='express-checkout-payments'>
        <h4>Express checkout</h4>
        <div className='payments-wrrapper'>
          { this.props.payments.map(payment => {
            const ExpressCheckoutButton = expressCheckoutButtons[payment]

            return <ExpressCheckoutButton key={payment} { ...this.props[payment] }/>
          }) }
        </div>
        { this.props.showSeparator &&
          <div className='separator'>{I18n.t('javascript.options.or').toUpperCase()}</div> }
      </div>
    )
  }

  /*
   * Private methods
   */
}

ExpressCheckoutPayments.propTypes = {
  payments:      PropTypes.array.isRequired,
  apple_pay:     PropTypes.object,
  google_pay:    PropTypes.object,
  showSeparator: PropTypes.bool
}

ExpressCheckoutPayments.defaultProps = {
  showSeparator: true
}

export default ExpressCheckoutPayments

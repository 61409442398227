import ConfirmationPage        from '@app/components/presentational/confirmation-page';
import ExpressCheckoutPayments from '@app/components/presentational/express-checkout-payments';
import QrCodePage              from '@app/components/presentational/qr-code-page';
import ReactOnRails            from 'react-on-rails';
import RedirectButton          from '@app/components/presentational/redirect-button';
import SpeiPage                from '@app/components/presentational/spei-page';

/**
 * Register & Initialize React Application Components.
 */
function initialize() {
  ReactOnRails.register({
    ConfirmationPage,
    RedirectButton,
    ExpressCheckoutPayments: ExpressCheckoutPayments,
    QrCodePage,
    SpeiPage
  });
}

const WebPaymentReact = { initialize };

export default WebPaymentReact;

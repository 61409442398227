const { cpfFormatter, cnpjFormatter } = require('genesis_wpf_js');

export function handleDocumentIdType(transactionType) {
  const transactionTypesWithDocumentId = ['credit_card', 'online_banking'];
  const transactionTypesWithFormattedDocumentId = ['boleto', 'pix'];
  const brazilianCountryCode = 'BR';


  if (transactionTypesWithDocumentId.includes(transactionType) &&
      !$('#document_id_section').data().transaction_type) {
    $('#document_id_section').data({ transaction_type: transactionType });
  }

  const $transactionForm = $(`.transaction-type-form.${transactionType}`);
  const documentId = {
    field: $transactionForm.find('#wpf_form_document_id'),
    label: $transactionForm.find('label[for="wpf_form_document_id"]')
  };

  if (
    !transactionTypesWithFormattedDocumentId.includes(transactionType) &&
    (!transactionTypesWithDocumentId.includes(transactionType) ||
    $('#wpf_form_billing_address_country').val() !== brazilianCountryCode)
  ) {
    documentId.field.off('input');

    return;
  }

  const $dropdown = $transactionForm.find('#document_id_types');
  const documentIdTypes = {
    CPF: {
      label: I18n.t('javascript.wpf_form.cpf.label'),
      placeholder: I18n.t('javascript.wpf_form.cpf.placeholder'),
      formatter: cpfFormatter
    },
    CNPJ: {
      label: I18n.t('javascript.wpf_form.cnpj.label'),
      placeholder: I18n.t('javascript.wpf_form.cnpj.placeholder'),
      formatter: cnpjFormatter
    }
  };

  documentId.field.attr('placeholder', I18n.t('javascript.wpf_form.cpf.placeholder'));
  documentId.label.html(I18n.t('javascript.wpf_form.cpf.label'));
  documentId.field.val('');

  $dropdown.on('change', function() {
    documentId.field.val('');
    const documentIdType = documentIdTypes[$(this).val()];

    documentId.field.attr('placeholder', documentIdType.placeholder);
    documentId.label.html(documentIdType.label);
  });

  documentId.field.on('input', function() {
    const formattedValue = documentId.field.val();
    const documentIdValue = documentIdTypes[$dropdown.val()].formatter(formattedValue);

    documentId.field.val(documentIdValue);
  });
}
